@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap');
@import "react-slideshow-image/dist/styles.css";

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus{
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button, textarea {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.homeBanner{
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}

.glass-effect{
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.1px);
  -webkit-backdrop-filter: blur(8.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.ant-modal-content, .ant-modal-title{
  background-color: #F7F9F9 !important;
}

.ant-pagination{
  display: none !important;
}